.not-found-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.not-found-text {
  font-size: 16px;
  color: #777;
}

.not-found-path {
  font-weight: bold;
  color: #ff0000;
}

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .not-found-title {
    font-size: 20px;
  }

  .not-found-text {
    font-size: 14px;
  }
}
