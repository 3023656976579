.sidebar-menu {
  height    : calc(100vh - 64px);
  /* Adjust height to be full minus any header/footer space */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.sidebar-menu::-webkit-scrollbar {
  width: 8px;
}

.sidebar-menu::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  /* Scrollbar thumb color */
  border-radius   : 4px;
}

.sidebar-menu::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
  /* Scrollbar track color */
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #36429f;
}

.ant-menu-title-content a {text-decoration: none;}