.ss-body {
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
  font-family: 'Poppins';
}

.ss1-img {
  width     : 100%;
  height    : auto;
  cursor    : pointer;
  transition: transform 0.3s ease-in-out;
}

.ss1-img:hover {
  transform: scale(1.1);
}

.ss-h4 {
  margin-top   : 10px;
  margin-bottom: 10px;
  font-size    : 16px;
  font-weight  : bold;
  color        : #233d7b;
  font-family: 'Poppins';
  margin-top: 25px !important;
}

.ss-body p {
  color        : #666;
  font-size    : 1rem;
  margin-bottom: 20px;
  font-family: 'Poppins';
}


/* BELOW OLD DESIGN */
.ss1,
.ss2,
.templates {
  text-align: center;
}

.templates img {
  width: 100%;
}

.template-bottom {
  text-align: left;
  margin-top: 20px;
}

.main-template {
  background: #eee;
  margin-top: 50px;
  padding   : 70px 0;
}

.template-text {
  width : 45%;
  margin: 20px auto 35px;
}

.template-bottom h4 {
  font-weight: 700;
}

.ss-body h4 {
  font-weight: 600;
  font-family: 'Poppins';
}
 

.ss-body {
  cursor: pointer;
}

.resume-edit {
  margin-top: 30px !important;
}

.pdf-img {
  width: 85%;
}

.home-head {
  font-weight  : 650;
  font-size    : 25px;
  margin-bottom: 20px;
  font-family: 'Poppins';
}

.update-date {
  font-size: 14px;
  color    : rgb(130, 139, 162);
  font-family: 'Poppins';
}

.home-text i {
  color: #233d7b;
}

.home-links {
  display       : flex;
  flex-direction: column;
}

.home-titleName {
  font-size    : 20px;
  margin-bottom: 0px !important;
}

.home-links a {
  text-decoration: none;
  color          : #000;
  cursor         : pointer;
}

.ss-body img {
  /* height: 390px; */
  width : 100%;
}

.start-btn {
  background-color: #233d7b;
}

.upload-btn-container .anticon {
  vertical-align: 1px;
}

.card {
  background      : rgb(255, 255, 255) !important;
  box-shadow      : rgba(13, 22, 40, 0.1) 2px 4px 8px;
  border-radius   : 8px;
  margin-bottom   : 30px !important;
  /* padding      : 15px 25px !important; */
  /* border-left  : 6px solid rgba(52, 152, 219, 0.7) !important; */
}

.dashboard-container .dashboard {
  display              : grid;
  grid-template-columns: 4fr 2fr;
  margin               : 0;
  padding              : 20px 20px 20px 30px;
  min-height           : 250px;
  background-color     : #fff;
  box-shadow           : 0 6px 12px rgb(30 10 58 / 16%);
  border-radius        : 20px;
}

.dashboard .profiles {
  display              : grid;
  grid-template-columns: 1fr 3fr;
  font-family: 'Poppins';
}

.dashboard-container .profile-image {
  display        : flex;
  flex-direction : column;
  align-items    : center;
  /* Center horizontally */
  justify-content: center;
  /* Center vertically */
  text-align     : center;
  /* Center text */
  padding        : 0;
  cursor         : pointer;
  position       : relative;
}

.dashboard-container .profile-img {
  width    : 155px;
  /* height: 206px; */
}

.dashboard-container .profile-percent {
  background   : #fff;
  bottom       : -20px;
  color        : red;
  padding      : 5.5px 16px;
  z-index      : 2;
  border-radius: 50px;
  width        : 57px;
}

.dashboard-container .profile-datas hr {
  margin-left: 13px;
  width      : 95%;
}

.dashboard-container .capitalize {
  text-transform: capitalize;
}

.dashboard-container .h3,
h3 {
  font-size: 1.5rem;
}

.dashboard-container .resume-button {
  display        : flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .dashboard-container .dashboard {
    display       : flex;
    flex-direction: column;
    padding       : 15px;
  }

  .dashboard .profiles {
    display      : block;
    margin-bottom: 10px;
  }

  .dashboard-container .profile-image {
    margin-bottom: 10px;
  }

  .dashboard-container .profile-img {
    width : 50%;
    height: auto;
  }

  .dashboard-container .profile-percent {
    margin-top: 5px;
  }

  .dashboard-container .profile-datas hr {
    margin-left: 0;
    margin-top : 10px;
    width      : 100%;
  }

  .dashboard-container .resume-button {
    flex-direction: column;
    align-items   : center;
    margin-top    : 10px;
  }

  .dashboard-container .resume-button button {
    transition: background-color 0.3s, color 0.3s;
    margin-top: 10px;
  }

  .dashboard-container .resume-button button:hover {
    background-color: #3ab380;
    color           : white;
  }
}

.boost-career .ant-card-body {
  padding: 0px !important;
}
@media (max-width: 768px) {

  .mobDashboard {
    text-align: center;
  }
  .dashboard-container .dashboard {
    display       : flex;
    flex-direction: column;
    padding       : 15px;
  }

  .dashboard .profiles {
    display      : block;
    margin-bottom: 10px;
  }

  .dashboard-container .profile-image {
    margin-bottom: 10px;
  }

  .dashboard-container .profile-img {
    width : 50%;
    height: auto;
  }

  .dashboard-container .profile-percent {
    margin-top: 5px;
  }

  .dashboard-container .profile-datas hr {
    margin-left: 0;
    margin-top : 10px;
    width      : 100%;
  }

  .dashboard-container .resume-button {
    flex-direction: column;
    align-items   : center;
    margin-top    : 10px;
  }

  .dashboard-container .resume-button button {
    margin-top: 10px;
  }
  .ss-h4 {
    font-size: 1rem;
  }
  .ss-body p{
    font-size: 0.81rem;
  }
}

.merchant .card1 .ant-card-head {
  text-align: center;
  background: rgb(59, 89, 152);
  color: rgb(255, 255, 255);
}

.merchant .card2 .ant-card-head {
  text-align: center;
  background: #39a883;
  color: rgb(255, 255, 255);
}

.merchant .card1 .body, .merchant .card2 .body {
  text-align: center;
    font-size: 16px;
    font-weight: 600;
}