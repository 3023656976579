nav {
  display            : flex;
  align-items        : center;
  justify-content    : space-between;
  padding            : 10px 20px;
  /* background-color: red; */
  box-shadow         : 0 5px 15px rgba(0, 0, 0, 0.06);
}

.header-navbar {
  background-color: #fff;
}

.logo-img {
  cursor: pointer;
}

#navbar {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  margin-top     : 16px;
}

#navbar li {
  list-style: none;
  padding   : 0 20px;
  position  : relative;
}

#navbar li a {
  text-decoration: none;
  font-size      : 1rem;
  font-weight    : 600;
  color          : #000;
  transition     : 0.3s ease-in-out;
}

#navbar li a:hover,
#navbar li a.active {
  color: #4bb28e;
}

#navbar li a:hover::after,
#navbar li a.active::after {
  content         : "";
  width           : 30%;
  height          : 2px;
  background-color: #4bb28e;
  position        : absolute;
  bottom          : -4px;
  left            : 20px;
}

#mobile {
  display: none;
}

@media screen and (max-width: 760px) {
  #navbar {
    display         : flex;
    flex-direction  : column;
    align-items     : flex-start;
    justify-content : flex-start;
    position        : fixed;
    top             : 60px;
    right           : 0px;
    height          : 100vh;
    width           : 300px;
    background-color: #374aa4;
    box-shadow      : 0 40px 60px rgba(0, 0, 0, 0.1);
    padding         : 40px 0 0 10px;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #navbar li a {
    color: #fff;
  }

  #mobile {
    display: block;
  }

  #navbar {
    display: none;
  }

  .resume-edit {
    margin-top: 15px !important;
  }
}

/* NEw  */

#menu-icon {
  display: block;
  cursor : pointer;
}

.bars-icon,
.close-icon {
  /* font-size: 2rem; */
  color: #000;
}

#mobile-menu {
  display         : block;
  background-color: #2f2f34d1;
  box-shadow      : 0 40px 60px rgba(0, 0, 0, 0.1);
  padding         : 10px 0 0 10px;
  position        : fixed;
  top             : 60px;
  right           : 0px;
  height          : calc(100vh - 60px);
  width           : 250px;
  z-index         : 1000;
}

#mobile-menu ul {
  list-style: none;
  padding   : 0;
  margin    : 0;
}

#mobile-menu li {
  margin-bottom: 10px;
}

#mobile-menu a {
  text-decoration: none;
  font-size      : 14px;
  font-weight    : 600;
  color          : #fff;
  transition     : 0.3s ease-in-out;
}

#mobile-menu a:hover,
#mobile-menu a.active {
  color: #4bb28e;
}

.firstLetter {
  padding       : 8px 10px 5px 9px;
  background    : #3758ac;
  font-size     : 16px;
  color         : #fff;
  border-radius : 50%;
  font-weight   : 600;
  cursor        : pointer;
  text-transform: uppercase;
}

/* Existing styles ... */

.sidebar {
  width           : 250px;
  background-color: #fff;
  position        : fixed;
  top             : 0;
  left            : -250px;
  height          : 100%;
  transition      : left 0.3s ease;
  box-shadow      : 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar.sidebar-open .sidebar {
  left: 0;
}

.menu-toggle {
  display: none;
  /* Hide the toggle on larger screens */
  cursor : pointer;
}

.sidebar-open .menu-toggle {
  display: block;
}

.menu-toggle .bar {
  width           : 30px;
  height          : 3px;
  background-color: #333;
  margin          : 6px 0;
}

/* ... Other styles ... */